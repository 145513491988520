import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  MenuItem,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Images } from "../../helpers/Images";
import axios from "axios";
import PrimaryButton from "../../Components/atoms/Primary/PrimaryButton";
import TextFieldInput from "../../Components/atoms/TextField/TextFieldInput";
import TextArea from "../../Components/atoms/TextArea/TextArea";
import { useForm, ValidationError } from "@formspree/react";
import { toast } from "react-toastify";

const ContactTemplate = () => {
  const [state, handleSubmit] = useForm("mrbzqzyp");
  const [Name, setName] = useState("");
  const [Country, setCountry] = useState("");
  const [Email, setEmail] = useState("");
  const [Message, setMessage] = useState("");

  useEffect(() => {
    if (state.succeeded) {
      setName("");
      setEmail("");
      setCountry("");
      setMessage("");
      toast.success(
        "Thank you for contacting us! We  will get back to you as soon as possible.",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
          style: { backgroundColor: "#002369" },
        }
      );
    }
  }, [state.succeeded]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          padding: {
            xs: "30px 20px",
            sm: "30px 60px",
            lg: "30px 120px",
            xl: "50px 120px",
          },
          background: `linear-gradient(80deg, #1a3978dd,#007effcc,#007effcc, #1a3978dd), #000000cc`,
          // background: `url(${Images.HomeBack})`,
          // backgroundSize: "cover",
          // backgroundPosition: "center",
          // backgroundRepeat: "no-repeat",
          minHeight: "380px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          boxShadow: "0px 0px 30px 0px rgba(0,0,0,0.15) inset",
        }}
      >
        <Typography
          variant={"h1"}
          sx={{
            textAlign: "center",
            fontSize: { xs: "40px", sm: "45px" },
            fontWeight: "500",
            color: "primary.light",
            marginBottom: "20px",
          }}
        >
          Get In Touch
        </Typography>
        <Typography
          variant={"p"}
          sx={{
            textAlign: "center",
            fontWeight: "500",
            fontSize: { xs: "15px", sm: "20px" },
            color: "primary.light",
          }}
        >
          Feel free to reach out to us with any inquiries regarding job
          opportunities, consultation services, or general queries. Our team is
          dedicated to providing prompt and helpful responses to all your needs.
          <br />
          We look forward to connecting with you soon!
        </Typography>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <a href={"tel:+201211150018"} style={{ textDecoration: "none" }}>
            <PrimaryButton
              classBtn={"center"}
              text={"Call Now"}
              style={{
                textTransform: "capitalize",
                borderRadius: "20px",
                color: "var(--secondary)",
                backgroundColor: "white",
                border: "2px solid var(--secondary)",
                height: "40px",
                padding: "0 25px",
                width: "fit-content",
                margin: "25px 5px 0",
              }}
            />
          </a>
          <a
            target="_blank"
            href="https://api.whatsapp.com/send?phone=%2B201211150018&fbclid=IwAR2Q0ES6pK05-OcugrLDDzFvd5qdd6SrzjAz07-6vnp_FUFVhxwE5pCxTXE"
            style={{ textDecoration: "none" }}
          >
            <PrimaryButton
              classBtn={"center"}
              text={"Chat Now"}
              style={{
                textTransform: "capitalize",
                borderRadius: "20px",
                color: "var(--secondary)",
                backgroundColor: "white",
                border: "2px solid var(--secondary)",
                height: "40px",
                padding: "0 25px",
                width: "fit-content",
                margin: "25px 5px 0",
              }}
            />
          </a>
        </div>
      </Box>
      <Box
        sx={{
          width: "100%",
          padding: { xs: "50px 20px", sm: "50px 60px", lg: "50px 120px" },
          maxWidth: "1500px",
          margin: "auto",
        }}
      >
        <Typography
          variant={"h2"}
          sx={{
            textAlign: "center",
            fontSize: "40px",
            fontWeight: "600",
            color: "primary.main",
            marginBottom: "20px",
          }}
        >
          Get Free Consultation
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            color: "primary.darker",
            fontSize: "16px",
            maxWidth: "900px",
            margin: "auto",
            marginTop: "0px",
            marginBottom: "30px",
          }}
        >
          Welcome to Skill Code Consultation form! Whether you're interested in
          joining our team or seeking expert consultation services, you've come
          to the right place. Please fill out the form below with the required
          information, and we'll get back to you as soon as possible.
        </Typography>
        <form onSubmit={handleSubmit} id="form">
          <div style={{ maxWidth: "600px", margin: "0 auto 20px" }}>
            <TextFieldInput
              Required
              name="name"
              type="text"
              placeholder={"Enter your full name.."}
              className="Input"
              label="Full Name"
              id={"input"}
              newValue={Name}
              change={(e) => setName(e.target.value)}
            />
          </div>
          <div style={{ maxWidth: "600px", margin: "0 auto 20px" }}>
            <TextFieldInput
              Required
              name="email"
              type="text"
              placeholder={"Enter your email.."}
              className="Input"
              label="Email"
              newValue={Email}
              change={(e) => setEmail(e.target.value)}
            />
          </div>
          <div style={{ maxWidth: "600px", margin: "0 auto 20px" }}>
            <TextFieldInput
              Required
              select
              label="Country"
              placeholder={"Choose your country"}
              name={"country"}
              fullWidth
              className={`Input`}
              variant="outlined"
              newValue={Country}
              change={(e) => setCountry(e.target.value)}
              SelectProps={{
                displayEmpty: true,
              }}
            >
              <MenuItem value="" className="" disabled>
                Choose Country
              </MenuItem>
              <MenuItem key={1} value={"Egypt"}>
                Egypt
              </MenuItem>
              <MenuItem key={2} value={"Saudi Arabia"}>
                Saudi Arabia
              </MenuItem>
              <MenuItem key={3} value={"Kuwait "}>
                Kuwait
              </MenuItem>
            </TextFieldInput>
          </div>
          <div style={{ maxWidth: "600px", margin: "0 auto 10px" }}>
            <TextArea
              Required
              name="text"
              placeholder={"Enter your message.."}
              className="Input"
              label="Message"
              newValue={Message}
              change={(e) => setMessage(e.target.value)}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <PrimaryButton
              type="submit"
              classBtn={"center"}
              text={"Submit"}
              disabled={state.submitting}
              style={{
                textTransform: "capitalize",
                borderRadius: "20px",
                color: "var(--secondary)",
                border: "2px solid var(--secondary)",
                padding: "5px 30px",
                width: "fit-content",
                marginBottom: "5px",
              }}
            />
          </div>
        </form>
        <Typography
          variant={"h2"}
          sx={{
            textAlign: "start",
            fontSize: "30px",
            fontWeight: "600",
            color: "primary.main",
            marginTop: "30px",
          }}
        >
          We are here for you..
        </Typography>
        <Typography
          sx={{
            textAlign: "start",
            color: "primary.darker",
            marginTop: "10px",
            fontSize: "18px",
          }}
        >
          For the fastest assistance, you can choose to "Get In Touch" to Call
          us or WhatsApp Chat or Email us!
          <br />
          Phone Support: +201211150018 - +201211150019 - +201005620076 <br />
          If you'd like to send an email directly, just write us at
          info@skill-code.com
        </Typography>
      </Box>
      {/* <Box
        id={"questions"}
        sx={{
          width: "100%",
          padding: { xs: "50px 20px", sm: "50px 60px", lg: "50px 120px" },
          maxWidth: "1500px",
          margin: "auto",
        }}
      >
        <Typography
          variant={"h1"}
          sx={{
            textAlign: "center",
            fontSize: { xs: "40px" },
            fontWeight: "500",
            color: "primary.darker",
          }}
        >
          Questions & Answers
        </Typography>
        <Box
          sx={{
            height: "3px",
            maxWidth: { xs: "100px", md: "200px" },
            width: "100%",
            backgroundColor: "primary.main",
            margin: "10px auto 0 auto",
          }}
        />
        <Box id={"questions"} sx={{ marginTop: "50px" }}>
          {[
            {
              question: "How to join ?",
              answer:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
            },
            {
              question: "What is the max number of students in a single team ?",
              answer:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
            },
            {
              question: "What is the max number of students in a single team ?",
              answer:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
            },
            {
              question: "What is the max number of students in a single team ?",
              answer:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
            },
          ].map((item) => (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>{item.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{item.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Box> */}
    </>
  );
};

export default ContactTemplate;
